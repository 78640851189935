import React from "react"
import styled from "styled-components"

import { H3, P } from "../typography"

import { media } from "../../utils/tokens"

const ListContainer = styled.ul`
  margin: 2rem 0 4rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  list-style: none;

  @media ${media.xl} {
    margin: ${props => (props.withTitle ? `1.5rem 0 6rem` : `4rem 0 6rem`)};
  }
`

const Item = styled.li`
  margin: 0 0 1.5rem;
  display: flex;

  &:last-of-type {
    margin: 0;
  }

  &::before {
    content: "—";
    margin: 0 .5rem 0 0;
  }

  @media ${media.xl} {
    &::before {
      margin: 0 1rem 0 0;
    }
  }
`

const ItemContainer = styled.span`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`

export const ListPara = ({ children, withTitle }) => (
  <ListContainer withTitle={withTitle}>{children}</ListContainer>
)

export const ListItems = ({ children, withTitle }) => (
  <Item>
    <ItemContainer>
      {withTitle ? <H3>{withTitle}</H3> : null}
      <P>{children}</P>
    </ItemContainer>
  </Item>
)
