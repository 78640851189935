import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { HeroSection, LargeSection } from "../components/containers"
import { H1, H2, H3, H4, P, MetaPara } from "../components/typography"
import { FlDivider, InsDivider } from "../components/dividers"
import { ListPara, ListItems } from "../components/lists/listParagraph"
import { CopyButton, CopyInput } from "../components/inputs/copyInput"
import { InlineLink } from "../components/links/inlineLink"

import useCopy from "../hooks/copy-hooks"

const PrivacyPolicyPage = () => {
  const { isCopied, copyTextToClipboard, textAreaRef } = useCopy()
  const organizationEmail = `info@concrezan.com`

  return (
    <Layout>
      <SEO
        title="Privacy Policy"
        description="Learn more about your privacy rights and how we use, share, and protect your information. Concrezan is an independent brand experience and service design consultancy."
      />
      <HeroSection>
        <H4>Effective Date: December 23, 2019</H4>
        <H1>Privacy Policy</H1>
        <P>
          This Privacy Policy describes how we collect, use, process, and
          disclose your information, including personal information, in
          conjunction with your access to and use of the Site.
        </P>
      </HeroSection>
      <FlDivider />
      <LargeSection>
        <P>
          If you see an undefined term in this Privacy Policy (such as “Site”),
          it has the same definition as in our{" "}
          <InlineLink to="/terms-of-use">Terms of Use</InlineLink>.
        </P>
        <P>
          When these Terms mention “Concrezan”, “service provider”, “we”, “us”,
          or “our”, it refers to Concrezan Studio.
        </P>
      </LargeSection>
      <FlDivider />
      <LargeSection>
        <H2>How we use the information we collect</H2>
        <P>
          We use, store, and process information, including personal
          information, about you to provide, understand, improve, and develop
          the Site, and comply with our legal obligations.
        </P>
        <ListPara>
          <ListItems>
            To provide you with information that you have requested, e.g.,
            newsletters, articles.
          </ListItems>
          <ListItems>
            To provide you with better ways of accessing information from this
            Site.
          </ListItems>
          <ListItems>To process and consider your job application.</ListItems>
          <ListItems>
            Operate, protect, improve, optimize, and personalize the Site and
            experiences related to it, such as by performing analytics and
            conducting research.
          </ListItems>
        </ListPara>
        <H3 withTitle="true">Our legal basis for processing</H3>
        <ListPara withTitle="true">
          <ListItems withTitle="Consent">
            We only use your personal information for this purpose if you have
            asked us to do so. You can withdraw your consent at any time.
          </ListItems>
          <ListItems withTitle="Our legitimate interests">
            We use your personal information to assess your job application, and
            to keep you updated throughout the application process.
          </ListItems>
        </ListPara>
        <P>
          For how we use your information that is collected using cookies and
          similar technologies, please see the “Cookies” section below.
        </P>
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Information We Collect</H2>
        <P>
          We only ask for personal information when we truly need it to provide
          our service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </P>
        <P>There are three general categories of information we collect.</P>
        <H3 withTitle="true">The information you give to us</H3>
        <ListPara withTitle="true">
          <ListItems withTitle="Communication with Concrezan">
            When you communicate with Concrezan through our Site, we require
            certain information such as your first name, email address and
            employer name. We also collect information about your communication
            with us and any information you choose to provide.
          </ListItems>
          <ListItems withTitle="Other Information">
            You may otherwise choose to provide us with information when you
            fill in a form, conduct a search, respond to surveys, or use other
            features of the Site.
          </ListItems>
        </ListPara>
        <H3 withTitle="true">
          Information we Automatically Collect from your use of the Site.
        </H3>
        <ListPara withTitle="true">
          <ListItems withTitle="Usage Information">
            We collect information about your interactions with the Site such as
            the pages or content you view, your searches, and other actions on
            the Site.
          </ListItems>
          <ListItems withTitle="Log Data and Device Information">
            We automatically collect log data and device information when you
            access and use the Site. That information includes, among other
            things: details about how you’ve used the Site (including if you
            clicked on links to third-party links), access dates and times,
            hardware and software information, cookie data, and pages you’ve
            viewed or engaged with before or after using the Site.
          </ListItems>
        </ListPara>
        <H3>Information We Collect from Third Parties</H3>
        <P>
          Our Site may link to external sites that are not operated by us. We do
          not control or supervise or respond to how third parties providing
          your information process your personal data, and any information
          request regarding the disclosure of your personal information to us
          should be directed to such third parties.
        </P>
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Cookies and Similar Technologies</H2>
        <P>
          We may use information obtained from cookies or similar technology.
          While you may disable the usage of cookies through your browser
          settings, the Site currently does not respond to a “Do Not Track”
          signal in the HTTP header from your browser due to a lack of
          standardization regarding how that signal should be interpreted.
        </P>
        <P>
          A cookie is a small data file that is transferred to your device (e.g.
          your phone or your computer). For example, a cookie could allow us to
          recognize your browser, while another could store your preferences.
          Therefore, when we refer to “you” in this section we mean your
          computer. We can recognize these cookies on subsequent visits and they
          allow us to remember you.
        </P>
        <P>
          Cookies come in many forms. We have set out below the main types and
          categories of cookies that are used. This section refers to all types
          and categories of cookies and not just those we use on our website.
        </P>
        <ListPara>
          <ListItems withTitle="Persistent cookies">
            These cookies remain on a user’s device for the time specified in
            the cookie. They activate each time that the user visits the website
            that created that particular cookie.
          </ListItems>
          <ListItems withTitle="Session cookies">
            These cookies allow website operators to link the actions of a user
            during a browser session. A browser session starts when a user opens
            the browser window and finishes when they close the browser window.
            Session cookies are created temporarily. Once you close the browser,
            all session cookies are deleted.
          </ListItems>
        </ListPara>
        <P>
          Most browsers automatically accept cookies, but you can modify your
          browser setting to decline cookies by visiting the Help portion of
          your browser’s toolbar.
        </P>
        <P>
          Your mobile device may allow you to control cookies through its
          settings function. Refer to your device manufacturer’s instructions
          for more information.
        </P>
        <P>
          If you choose to decline cookies, some parts of the Site may not work
          as intended or may not work at all.
        </P>
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Other Important Information</H2>
        <P>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use or
          modification.
        </P>
        <P>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required by law.
        </P>
        <P>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          the desired services.
        </P>
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Changes to this privacy policy</H2>
        <P>
          Concrezan reserves the right to modify this Privacy Policy at any time
          per this provision. If we make changes to this Privacy Policy, we will
          post the revised Privacy Policy on the Site and update the “Last
          Updated” date at the top of this Privacy Policy. By your continued
          access to or use of the Site will be subject to the revised Privacy
          Policy.
        </P>
      </LargeSection>
      <InsDivider />
      <LargeSection>
        <H2>Contact Us</H2>
        <P>
          If you have any questions about how we handle user data and personal
          information, feel free to contact us.
        </P>
        <H2>Address</H2>
        <P>
          Suite 20-01,
          <br />
          Level 20,
          <br />
          The Pinnacle,
          <br />
          Persiaran Lagoon, Bandar Sunway,
          <br />
          47500, Subang Jaya,
          <br />
          Selangor, Malaysia
        </P>
        <H3>Email</H3>
        <P>
          <CopyButton onClick={copyTextToClipboard}>
            {organizationEmail}
          </CopyButton>
        </P>
        <MetaPara>{isCopied}</MetaPara>
        <CopyInput ref={textAreaRef} defaultValue={organizationEmail} />
      </LargeSection>
    </Layout>
  )
}

export default PrivacyPolicyPage
